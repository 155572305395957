import { useAuth } from "~/stores/auth.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path === "/setup" || to.path === "/login") {
    return;
  }

  if (!useAuth().employeeSession) {
    return navigateTo("/login");
  }
});
