import { defineStore } from "pinia";
import { useApi } from "~/composables/useApi.js";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

export const useAuth = defineStore("auth", {
  state: () => ({
    token: useCookie("terminal_token", {
      maxAge: 60 * 60 * 24 * 7,
    }),
    context: null,
    echo: null,
  }),

  actions: {
    async login(terminalId, merchantId) {
      const runtimeConfig = useRuntimeConfig();

      const token = useCookie("token");

      const response = await $fetch(
        runtimeConfig.public.apiUrl +
          "/api/v1/merchant/terminals/" +
          terminalId +
          "/tokens",
        {
          method: "POST",
          headers: {
            Merchant: merchantId,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.value,
          },
        },
      );

      this.token = response.data.attributes.value;

      token.value = null;

      await this.initialize();
    },

    async initialize() {
      if (!this.token) {
        throw new Error("No token present");
      }

      if (this.context) {
        return;
      }

      await this.refresh();

      setInterval(() => this.refresh(), 10 * 60 * 1000);

      await this.loadContext();

      this.listen();
    },

    async refresh() {
      const runtimeConfig = useRuntimeConfig();

      try {
        const response = await $fetch(
          runtimeConfig.public.apiUrl + "/api/v1/auth/tokens/refresh",
          {
            method: "POST",
            headers: { Authorization: "Bearer " + this.token },
          },
        );

        this.token = response.data.attributes.value;
      } catch (error) {
        this.token = null;

        this.context = null;

        this.echo = null;

        navigateTo("/setup");
      }
    },

    async loadContext() {
      const runtimeConfig = useRuntimeConfig();

      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/terminal/context",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        },
      );

      this.context = response.data;
    },

    async startEmployeeSession(pinCode) {
      const runtimeConfig = useRuntimeConfig();

      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/terminal/employee-sessions",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          method: "POST",
          body: {
            pin_code: pinCode.toString(),
          },
        },
      );

      this.context.attributes.employee_session = {
        id: response.data.id,
        employee_first_name: response.data.attributes.employee_first_name,
        employee_last_name: response.data.attributes.employee_last_name,
        employee_can_store_generic_discount_lines:
          response.data.attributes.employee_can_store_generic_discount_lines,
        employee_id: response.data.attributes.employee_id,
      };
    },

    async closeEmployeeSession() {
      const runtimeConfig = useRuntimeConfig();

      await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/terminal/employee-session/close",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
            "Employee-Session": this.context.attributes.employee_session.id,
          },
          method: "POST",
        },
      );

      this.context.attributes.employee_session = null;
    },

    async startChannelSettlement(paymentMethodSettlements) {
      await useApi("/channel-settlements", {
        method: "POST",
        body: {
          terminal_id: this.id,
          payment_method_settlements: paymentMethodSettlements,
        },
      });

      await this.loadContext();
    },

    async closeChannelSettlement(paymentMethodSettlements, print) {
      await useApi(
        "/channel-settlements/" + this.channelSettlement.id + "/close",
        {
          method: "POST",
          body: {
            payment_method_settlements: paymentMethodSettlements,
            print,
          },
        },
      );

      this.context.attributes.channel_settlement = null;
    },

    async changeTerminal(id) {
      const runtimeConfig = useRuntimeConfig();

      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/terminal/tokens",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          method: "POST",
          body: {
            terminal_id: id,
          },
        },
      );

      this.token = response.data.attributes.value;

      await this.loadContext();

      this.listen();
    },

    listen() {
      const runtimeConfig = useRuntimeConfig();

      this.echo = new Echo({
        broadcaster: "pusher",
        key: runtimeConfig.public.pusherAppKey,
        cluster: runtimeConfig.public.pusherAppCluster,
        forceTLS: true,
        authorizer: (channel, options) => {
          return {
            authorize: (socketId, callback) => {
              $fetch(
                runtimeConfig.public.apiUrl +
                  "/api/v1/terminal/broadcasting/auth",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.token,
                  },
                  body: JSON.stringify({
                    socket_id: socketId,
                    channel_name: channel.name,
                  }),
                },
              )
                .then((data) => {
                  callback(false, data);
                })
                .catch((error) => {
                  callback(true, error);
                });
            },
          };
        },
      });

      this.echo
        .private("terminals." + this.id)
        .listen(".channel-settlement.finished", async () => {
          await this.loadContext();

          if (useRoute().path !== "login") {
            await this.signOut();
          }
        })
        .listen(".channel-settlement.not-finished", async () => {
          await this.loadContext();

          if (useRoute().path !== "login") {
            await navigateTo("/channel-settlements/close");
          }
        })
        .notification(
          () => this.context.attributes.unread_notification_count++,
        );
    },

    getTerminalFolder(id = null) {
      return this.terminalFolders.find(
        (terminalFolder) => terminalFolder.id === (id || this.terminalFolderId),
      );
    },

    getProduct(id) {
      return this.products.find((product) => product.id === id);
    },

    getModifierGroup(id) {
      return this.modifierGroups.find(
        (modifierGroup) => modifierGroup.id === id,
      );
    },

    getModifier(id) {
      return this.modifierGroups
        .reduce(
          (result, modifierGroup) => [...result, ...modifierGroup.modifiers],
          [],
        )
        .find((modifier) => modifier.id === id);
    },

    getBundle(id) {
      return this.bundles.find((bundle) => bundle.id === id);
    },

    getBundleChoice(id) {
      return this.bundleChoices.find((bundleChoice) => bundleChoice.id === id);
    },

    getBundleItem(id) {
      return this.bundles
        .reduce((result, bundle) => [...result, ...bundle.bundle_items], [])
        .find((bundleItem) => bundleItem.id === id);
    },

    resetUnreadNotifications() {
      this.context.attributes.unread_notification_count = 0;
    },

    async signOut() {
      await this.closeEmployeeSession();

      navigateTo("/login");
    },
  },

  getters: {
    id: (state) => state.context?.id,
    name: (state) => state.context?.attributes.name,
    locale: (state) => state.context?.attributes.locale,
    printsChannelSettlements: (state) =>
      state.context?.attributes.prints_channel_settlements,
    unreadNotificationCount: (state) =>
      state.context?.attributes.unread_notification_count,
    earlyArrivalLimit: (state) => state.context?.attributes.early_arrival_limit,
    reservationHoursFrom: (state) =>
      state.context?.attributes.reservation_hours_from,
    reservationHoursTo: (state) =>
      state.context?.attributes.reservation_hours_to,
    terminalFolderId: (state) => state.context?.attributes.terminal_folder_id,
    storeId: (state) => state.context?.attributes.store_id,
    employeeSession: (state) => state.context?.attributes.employee_session,
    channelSettlement: (state) => state.context?.attributes.channel_settlement,
    paymentMethods: (state) =>
      state.context?.attributes.terminal_payment_methods,
    bottomMenuItems: (state) => state.context?.attributes.bottom_menu_items,
    cancellationReasons: (state) =>
      state.context?.attributes.cancellation_reasons,
    accountConfigurations: (state) =>
      state.context?.attributes.account_configurations,
    cashTransferCategories: (state) =>
      state.context?.attributes.cash_transfer_categories,
    terminalFolders: (state) => state.context?.attributes.terminal_folders,
    featuredTerminalFolders: (state) =>
      state.context?.attributes.featured_terminal_folders,
    floors: (state) => state.context?.attributes.floors,
    products: (state) => state.context?.attributes.products,
    modifierGroups: (state) => state.context?.attributes.modifier_groups,
    bundles: (state) => state.context?.attributes.bundles,
    bundleChoices: (state) => state.context?.attributes.bundle_choices,
    orderProviders: (state) => state.context?.attributes.order_providers,
    deliveryProviders: (state) => state.context?.attributes.delivery_providers,
    terminals: (state) => state.context?.attributes.terminals,
  },
});
