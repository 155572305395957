import { useAuth } from "~/stores/auth.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (
    to.path === "/setup" ||
    to.path === "/login" ||
    to.path === "/channel-settlements/create"
  ) {
    return;
  }

  if (!useAuth().channelSettlement) {
    return navigateTo("/channel-settlements/create");
  }
});
