import { useAuth } from "~/stores/auth";

export const useApi = async (path, options = {}) => {
  const auth = useAuth();
  const runtimeConfig = useRuntimeConfig();

  return await $fetch(runtimeConfig.public.apiUrl + "/api/v1/terminal" + path, {
    ...options,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + auth.token,
      "Employee-Session": auth.employeeSession?.id,
      ...options.headers,
    },
  });
};
