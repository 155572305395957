import { useAuth } from "~/stores/auth.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (
    to.path === "/setup" ||
    to.path === "/login" ||
    to.path === "/channel-settlements/close"
  ) {
    return;
  }

  if (useAuth().channelSettlement?.closed_at) {
    return navigateTo("/channel-settlements/close");
  }
});
