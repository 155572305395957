import { default as createWszcyc4VAcMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/cash-transfers/create.vue?macro=true";
import { default as _91id_93BiTdGQ5TKKMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/[id].vue?macro=true";
import { default as createUSVQpAeKlAMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/create.vue?macro=true";
import { default as index2XcrmilUq6Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/index.vue?macro=true";
import { default as closeNyHmaJtL6CMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlements/close.vue?macro=true";
import { default as createCFt40mylZ8Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlements/create.vue?macro=true";
import { default as indexheUCQKpyAGMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/index.vue?macro=true";
import { default as loginXQl2XJRcA5Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/login.vue?macro=true";
import { default as _91id_93y7N3FIXou7Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/[id].vue?macro=true";
import { default as create9ZHWSjVhIgMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/create.vue?macro=true";
import { default as indexIU5yEoeodHMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/index.vue?macro=true";
import { default as print_45jobs3iTHVCSI1CMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/print-jobs.vue?macro=true";
import { default as indexF1Cht5pbhoMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/reservations/index.vue?macro=true";
import { default as laneswCCDw45zYUMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/reservations/lanes.vue?macro=true";
import { default as setupHqkSjDUOuPMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/setup.vue?macro=true";
import { default as editTCq8VgJFq6Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexBUPr0cP9xsMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createhiiUMZIaVoMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/create.vue?macro=true";
import { default as index7Xx0VPdrlQMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statusesvueMveceguMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses.vue?macro=true";
import { default as indexD99c6a6mULMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitGtGOqGKDDOMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93huBQ3458kPMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as create8nxUlc1mdkMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_934prtOZrYgDMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId].vue?macro=true";
import { default as indexSPooC86FiKMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/index.vue?macro=true";
import { default as _91id_93vbLoQpL77PMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/[id].vue?macro=true";
import { default as createH7rGpBNDcTMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/create.vue?macro=true";
import { default as indexdqqjLmbYOSMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "cash-transfers-create",
    path: "/cash-transfers/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/cash-transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots-id",
    path: "/channel-settlement-snapshots/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/[id].vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots-create",
    path: "/channel-settlement-snapshots/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/create.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots",
    path: "/channel-settlement-snapshots",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlement-snapshots/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlements-close",
    path: "/channel-settlements/close",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlements/close.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlements-create",
    path: "/channel-settlements/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/channel-settlements/create.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginXQl2XJRcA5Meta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupHqkSjDUOuPMeta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statusesvueMveceguMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93huBQ3458kPMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id",
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20240918194613/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]